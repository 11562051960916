[data-v-1b49f49f] .report-dialog .el-dialog__body .cus-divider {
  margin: 24px 10px;
}
[data-v-1b49f49f] .report-dialog .el-dialog__body .cus-divider .el-divider__text {
    color: #606266;
    font-weight: 500;
}
[data-v-1b49f49f] .report-dialog .el-dialog__body .template-mapping-wrapper {
  width: 80%;
  padding: 10px;
  border: 1px solid #ddd;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
  margin-left: 100px;
}
[data-v-1b49f49f] .report-dialog .el-dialog__body .template-mapping-wrapper .template-mapping-header {
    width: 100%;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
[data-v-1b49f49f] .report-dialog .el-dialog__body .template-mapping-wrapper .template-mapping-header .template-mapping-column {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
      text-align: center;
}
[data-v-1b49f49f] .report-dialog .el-dialog__body .template-mapping-wrapper .template-mapping-content {
    width: 100%;
    min-height: 300px;
}
[data-v-1b49f49f] .report-dialog .el-dialog__body .template-mapping-wrapper .template-mapping-content .template-mapping-item {
      width: 100%;
      height: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
          justify-content: space-around;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
[data-v-1b49f49f] .report-dialog .el-dialog__body .template-mapping-wrapper .template-mapping-content .template-mapping-item .template-mapping-fields {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        text-align: center;
        padding: 0px 10px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
